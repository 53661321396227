import { mapGetters } from 'vuex';
import { actionTypes as userActions } from '~/store/user';

export default {
    computed: {
        ...mapGetters(['isLoggedIn', 'redirectToAfterLogout']),
    },
    watch: {
        isLoggedIn(newValue) {
            if (!newValue && this.redirectToAfterLogout) {
                this.$cookies.set('auth.redirect', undefined);
                setTimeout(async () => {
                    await this.$router.push(this.localePath(this.redirectToAfterLogout));

                    // Reset the flag after use
                    this.$store.dispatch(userActions.CLEAR_REDIRECT_TO_AFTER_LOGOUT);
                });
            }
        },
    },
};
