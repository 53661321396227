/* eslint-disable no-param-reassign, no-shadow */

export const mutationTypes = {
    SLIDEUP_MODAL_SET: 'SLIDEUP_MODAL/SET_MODAL',
};

export const actionTypes = {
    SLIDEUP_MODAL_OPEN: 'SLIDEUP_MODAL/OPEN',
    SLIDEUP_MODAL_CLOSE: 'SLIDEUP_MODAL/CLOSE',
};

export const state = () => ({
    config: null,
});

export const getters = {
    slideUpModalConfig: state => state.config,
};

export const mutations = {
    [mutationTypes.SLIDEUP_MODAL_SET](state, config) {
        state.config = config;
    },
};

export const actions = {
    [actionTypes.SLIDEUP_MODAL_OPEN]({ commit }, config) {
        return new Promise((resolve, reject) => {
            const extendedConfig = {
                ...config,
                resolve,
                reject,
            };
            commit(mutationTypes.SLIDEUP_MODAL_SET, extendedConfig);
        });
    },
    [actionTypes.SLIDEUP_MODAL_CLOSE]({ commit }) {
        commit(mutationTypes.SLIDEUP_MODAL_SET, null);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
};
