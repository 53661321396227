import { actionTypes as userActions } from '@/store/user';

export default function ({ store, $axios }) {
    const handleJwtError = error => {
        const jwtErrorSlugs = [
            'jwt_exception',
            'jwt_refresh_failed',
            'jwt_token_expired',
            'jwt_token_invalid',
            'jwt_token_blacklisted',
        ];

        if (jwtErrorSlugs.includes(error.slug)) {
            store.dispatch(userActions.LOGOUT, { name: 'login', query: { 'auth-status': 'invalid-jwt' } });
        }
    };

    $axios.interceptors.response.use(
        response => response,
        error => {
            if (error && error.slug) {
                handleJwtError(error);
            }

            return Promise.reject(error);
        }
    );

    store.$handleJwtError = handleJwtError;
}
