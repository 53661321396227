/* eslint-disable no-param-reassign, no-shadow */
import { actionTypes as myVehiclesActionTypes } from './myVehicles';
import { CONFIG_BY_COUNTRY } from './localization';
import { actionTypes as modalActions } from '~/store/modal';
import IdVerificationService from '~/services/IdVerificationService';
import AuthService from '~/services/AuthService';
import MeService from '~/services/MeService';
import { STATUS_PENDING_VERIFICATION, STATUS_DENIED } from '~/config/idVerification';
import { swissCountryCodes } from '~/config/countryCodeLists';
import { BALOISE_INSURANCE_LIMITS } from '~/config/insurance';

// Number of milliseconds to display the saved message
export const SAVE_MESSAGE_TIMEOUT = 6 * 1000;

export const mutationTypes = {
    SAVED_MESSAGE_SET: 'USER/SAVED_MESSAGE_SET',
    SAVED_MESSAGE_CLEAR: 'USER/SAVED_MESSAGE_CLEAR',

    ROLE_OVERRIDE_TOGGLE: 'USER/ROLE_OVERRIDE_TOGGLE',
    ROLE_OVERRIDE_CLEAR: 'USER/ROLE_OVERRIDE_CLEAR',

    FAVORED_ASSET_SET: 'USER/FAVORED_ASSET_SET',
    FAVORED_ASSET_CLEAR: 'USER/FAVORED_ASSET_CLEAR',

    ID_VERIFICATION_SET: 'USER/ID_VERIFICATION_SET',

    PAYMENT_METHODS_SET: 'USER/PAYMENT_METHODS_SET',
    SELECTED_PAYMENT_METHOD_SET: 'USER/SELECTED_PAYMENT_METHOD_SET',

    AUTH_DATA_SET: 'USER/AUTH_DATA_SET',

    SET_REDIRECT_TO_AFTER_LOGOUT: 'USER/SET_REDIRECT_TO_AFTER_LOGOUT',
};

export const actionTypes = {
    CLEAR: 'USER/CLEAR',

    LOGIN: 'USER/LOGIN',
    LOGOUT: 'USER/LOGOUT',

    FETCH_ME: 'USER/FETCH_ME',
    SAVE_ME: 'USER/SAVE_ME',
    CONFIRM_CONTACT_DETAILS: 'USER/CONFIRM_CONTACT_DETAILS',

    SHOW_SAVED_MESSAGE: 'USER/SHOW_SAVED_MESSAGE',
    FETCH_ID_VERIFICATION: 'USER/FETCH_ID_VERIFICATION',

    FETCH_PAYMENT_METHODS: 'USER/FETCH_PAYMENT_METHODS',
    SET_SELECTED_PAYMENT_METHOD: 'USER/SET_SELECTED_PAYMENT_METHOD',

    ADMIN_ROLE_OVERRIDE_TOGGLE: 'USER/ADMIN_ROLE_OVERRIDE_TOGGLE',

    SET_FAVORED_ASSET: 'USER/SET_FAVORED_ASSET',
    CLEAR_FAVORED_ASSET: 'USER/CLEAR_FAVORED_ASSET',

    SET_AUTH_DATA: 'USER/SET_AUTH_DATA',

    SET_REDIRECT_TO_AFTER_LOGOUT: 'USER/SET_REDIRECT_TO_AFTER_LOGOUT',
    CLEAR_REDIRECT_TO_AFTER_LOGOUT: 'USER/CLEAR_REDIRECT_TO_AFTER_LOGOUT',
};

export const state = () => ({
    savedMessage: null,
    roleOverride: null,
    favoredAsset: null,
    userIdVerification: null,
    paymentMethods: null,
    selectedSavedCard: null,
    userAuthData: null,
    redirectToAfterLogout: null,
});

const isRoleFactory = role => (_state, getters) => {
    if (!getters.user || !getters.user.role) {
        return false;
    }

    if (getters.user.role === role) {
        return true;
    }

    return getters.user.role === 'admin' && getters.roleOverride === role;
};

export const getters = {
    isLoggedIn: (_state, _getters, rootState) => rootState.auth.loggedIn,
    user: (_state, _getters, rootState) => {
        if (!rootState.auth.user) {
            return null;
        }

        return rootState.auth.user;
    },
    userCurrency: (_state, getters) => {
        if (getters.user && getters.user.role !== 'admin') {
            return getters.user.currency;
        }

        return getters.defaultCurrency;
    },
    userStripeAccount: (_state, getters, _rootState, rootGetters) => {
        if (getters.user && getters.user.stripeAccount) {
            return getters.user.stripeAccount;
        }

        if (!rootGetters.myFirstVehicle || !rootGetters.myFirstVehicle.country) {
            return null;
        }

        const country = swissCountryCodes.includes(rootGetters.myFirstVehicle.country)
            ? 'CH'
            : rootGetters.myFirstVehicle.country;

        return CONFIG_BY_COUNTRY[country].stripeAccount;
    },
    userAuthData: state => state.userAuthData,
    userIdVerification: state => state.userIdVerification,
    isPendingIdVerification: state => state.userIdVerification?.status === STATUS_PENDING_VERIFICATION,
    isIdVerificationDenied: state => state.userIdVerification?.status === STATUS_DENIED,
    userPaymentMethods: state => state.paymentMethods,
    selectedSavedCard: state => state.selectedSavedCard,
    roleOverride: state => state.roleOverride,
    userAvatarUrl: (_state, getters) => (getters.user ? getters.user.avatarUrl : null),
    isAdmin: (_state, getters) => getters.user && getters.user.role === 'admin',
    isEditor: isRoleFactory('editor'),
    isRenter: isRoleFactory('renter'),
    isOwner: isRoleFactory('owner'),
    hasProtectedNameAndAddress: (_state, getters) => getters.user && getters.user.hasProtectedNameAndAddress,
    requiresStripeAccountVerification: (_state, getters) =>
        getters.user &&
        isRoleFactory('owner') &&
        ['restricted', 'restricted_soon'].includes(getters.user.stripeAccountVerificationStatus),
    allowMultipleVehicles: (_state, getters) => getters.user && getters.user.canManageMultipleAssets,
    profileSavedMessage: state => state.savedMessage,
    favoredAsset: state => state.favoredAsset,
    isFullyOnboarded: (_state, getters) => (getters.user && getters.user.isFullyOnboarded) || false,
    userCanBuyBaloiseInsurance: (_state, getters) =>
        getters.user &&
        getters.isRenter &&
        BALOISE_INSURANCE_LIMITS.allowedRenterCountryCodes.includes(getters.user.country),
    redirectToAfterLogout: state => state.redirectToAfterLogout,
};

export const mutations = {
    [mutationTypes.SAVED_MESSAGE_SET](state, payload) {
        state.savedMessage = payload;
    },
    [mutationTypes.SAVED_MESSAGE_CLEAR](state) {
        state.savedMessage = null;
    },

    [mutationTypes.ROLE_OVERRIDE_TOGGLE](state, role) {
        if (state.roleOverride === role) {
            state.roleOverride = null;
        } else {
            state.roleOverride = role;
        }
    },
    [mutationTypes.ROLE_OVERRIDE_CLEAR](state) {
        state.roleOverride = null;
    },

    [mutationTypes.FAVORED_ASSET_SET](state, payload) {
        state.favoredAsset = payload;
    },
    [mutationTypes.FAVORED_ASSET_CLEAR](state) {
        state.favoredAsset = null;
    },

    [mutationTypes.ID_VERIFICATION_SET](state, payload) {
        state.userIdVerification = payload;
    },

    [mutationTypes.PAYMENT_METHODS_SET](state, payload) {
        state.paymentMethods = payload;
    },

    [mutationTypes.SELECTED_PAYMENT_METHOD_SET](state, payload) {
        state.selectedSavedCard = payload;
    },

    [mutationTypes.AUTH_DATA_SET](state, payload) {
        state.userAuthData = payload;
    },

    [mutationTypes.SET_REDIRECT_TO_AFTER_LOGOUT](state, value) {
        state.redirectToAfterLogout = value;
    },
};

export const actions = {
    [actionTypes.CLEAR]({ commit }) {
        commit(mutationTypes.SAVED_MESSAGE_CLEAR);
        commit(mutationTypes.ROLE_OVERRIDE_CLEAR);
        commit(mutationTypes.ID_VERIFICATION_SET, null);
    },

    [actionTypes.ADMIN_ROLE_OVERRIDE_TOGGLE]({ commit }, role) {
        commit(mutationTypes.ROLE_OVERRIDE_TOGGLE, role);
    },

    async [actionTypes.FETCH_ME]({ dispatch, getters }) {
        await new MeService(this).getMe();

        if (getters.isOwner) {
            await dispatch(myVehiclesActionTypes.FETCH_VEHICLES);
        }

        return getters.user;
    },

    async [actionTypes.SAVE_ME](_, data) {
        return await new MeService(this).updateMe(data);
    },

    [actionTypes.SHOW_SAVED_MESSAGE]({ commit }, message) {
        commit(mutationTypes.SAVED_MESSAGE_SET, message);

        setTimeout(() => {
            commit(mutationTypes.SAVED_MESSAGE_CLEAR);
        }, SAVE_MESSAGE_TIMEOUT);
    },

    [actionTypes.SET_FAVORED_ASSET]({ commit }, asset) {
        commit(mutationTypes.FAVORED_ASSET_SET, asset);
    },

    [actionTypes.CLEAR_FAVORED_ASSET]({ commit }) {
        commit(mutationTypes.FAVORED_ASSET_CLEAR);
    },

    [actionTypes.SET_AUTH_DATA]({ commit }, data) {
        commit(mutationTypes.AUTH_DATA_SET, data);
    },

    async [actionTypes.LOGIN]({ dispatch, getters, rootGetters }, credentials) {
        await new AuthService(this).login(credentials);
        await dispatch(actionTypes.FETCH_ME);

        if (getters.isOwner) {
            await dispatch(myVehiclesActionTypes.FETCH_VEHICLES);

            if (!getters.user.hasBankAccount && getters.user.confirmedBookings > 0) {
                dispatch(modalActions.MODAL_OPEN, {
                    component: 'NoBankAccountModal',
                    closeable: false,
                });

                return;
            }

            if (getters.user.source !== 'camptoo' || !rootGetters.myFirstVehicle) {
                return;
            }

            const welcomeFlag = JSON.parse(localStorage.getItem('camptoo_banner'));

            if (
                !welcomeFlag &&
                (!rootGetters.myFirstVehicle?.currentOdometerValue ||
                    !rootGetters.myFirstVehicle?.insuranceValue ||
                    rootGetters.myFirstVehicle?.images.length < 1)
            ) {
                localStorage.setItem('camptoo_banner', JSON.stringify(true));
                dispatch(modalActions.MODAL_OPEN, {
                    component: 'CamptooWelcomeModal',
                    closeable: false,
                    header: 'logo',
                });
            }
        }

        return getters.user;
    },

    [actionTypes.LOGOUT]({ commit }, redirectToAfterLogout = null) {
        // Will be cleared after the redirect, see logoutRedirectMixin.js
        commit(mutationTypes.SET_REDIRECT_TO_AFTER_LOGOUT, redirectToAfterLogout);

        return new AuthService(this).logout();
    },

    [actionTypes.FETCH_PAYMENT_METHODS]({ commit }, stripeAccount) {
        return new MeService(this).getPaymentMethods(stripeAccount).then(paymentMethods => {
            commit(mutationTypes.PAYMENT_METHODS_SET, paymentMethods);

            return paymentMethods;
        });
    },

    [actionTypes.SET_SELECTED_PAYMENT_METHOD]({ commit }, paymentMethod) {
        commit(mutationTypes.SELECTED_PAYMENT_METHOD_SET, paymentMethod);
    },

    async [actionTypes.FETCH_ID_VERIFICATION]({ commit }) {
        const service = new IdVerificationService(this);

        try {
            const idVerification = await service.getForUser();
            commit(mutationTypes.ID_VERIFICATION_SET, idVerification);

            return idVerification;
        } catch (error) {
            if (!error.slug || error.slug !== 'model_not_found') {
                this.$sentry.captureException(error);
            }

            commit(mutationTypes.ID_VERIFICATION_SET, null);

            return null;
        }
    },

    async [actionTypes.CONFIRM_CONTACT_DETAILS]({ dispatch }) {
        await new MeService(this).confirmContactDetails();

        await dispatch(actionTypes.FETCH_ME);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
    mutationTypes,
};
