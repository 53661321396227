import { mapGetters } from 'vuex';
import { actionTypes as modalActions } from '~/store/modal';
import modalMixin from '~/mixins/modalMixin';

export default {
    mixins: [modalMixin],
    computed: {
        ...mapGetters([
            'userAuthData',
            'user',
            'isLoggedIn',
            'isAdmin',
            'isImpersonating',
            'hasAnyVehicleWithOmocomInsurance',
        ]),
    },
    mounted() {
        const query = this.$route.query || {};

        if (this.isLoggedIn) {
            if (query.popup === 'stripe-account-verification') {
                this.displayStripeAccountVerificationModal();

                return;
            }

            this.displayObtrusiveModalsInOrder();
        } else {
            switch (query.popup) {
                case 'login':
                    this.displayLoginModal();
                    break;
                case 'sign-up':
                    this.displaySignUpModal();
                    break;
            }
        }

        if (query.popup === 'contact') {
            this.displaySupportRequestModal();
        }
    },
    methods: {
        handleOnboarding() {
            const isRenterSignup = /renter-signup/.test(this.$route.name);
            const isLoginInBooking = /vehicle-id-book-login/.test(this.$route.name);

            if (this.userAuthData && !isRenterSignup && !isLoginInBooking) {
                return this.$store.dispatch(modalActions.MODAL_OPEN, {
                    component: 'OnboardingModal',
                    data: {
                        onboarding: this.userAuthData,
                    },
                    style: { lesserPadding: true },
                });
            }
        },
    },
    watch: {
        $route: {
            handler() {
                this.$nextTick(() => this.handleOnboarding());
            },
            immediate: true,
        },
    },
};
