import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isDate from 'date-fns/isDate';

/**
 * Prints the given value as date/time with the given format
 * @param {number} value Value to format
 */
export function dateFormatFilter({ $utils }) {
    return (value, formatSpec) => {
        const date = isDate(value) ? value : parseISO(value);
        const dateFormat = formatSpec || $utils.defaultDateFormat();

        return format(date, dateFormat, { locale: $utils.getDateFnsLocale() });
    };
}

export function datetimeFormatFilter({ $utils }) {
    return value => {
        const date = isDate(value) ? value : parseISO(value);
        const dateFormat = `${$utils.defaultDateFormat()} HH:mm`;

        return format(date, dateFormat, { locale: $utils.getDateFnsLocale() });
    };
}

export function fullDateFormatFilter({ $utils, i18n }) {
    return value => {
        const date = isDate(value) ? value : parseISO(value);
        const dateFormat = i18n.locale === 'de' ? 'eee, d. MMM yyyy' : 'eee, dd MMM yyyy';

        return format(date, dateFormat, { locale: $utils.getDateFnsLocale() });
    };
}

/**
 * Prints the date with the following rules:
 * 1. Different year: Wed, 30 Dec 2024 — Fri, 03 Jan 2025
 * 2. Same year: Wed, 25 Nov - Fr, 30 Dec 2024
 *
 * returns string | based on 'position' returns either start or end date
 */
export function compactDateRangeFormatFilter({ $utils, i18n }) {
    return (startDate, endDate, position) => {
        const start = isDate(startDate) ? startDate : parseISO(startDate);
        const end = isDate(endDate) ? endDate : parseISO(endDate);

        const sameYear = start.getFullYear() === end.getFullYear();

        const weekdayFormat = 'EEE';
        const dayFormat = i18n.locale === 'de' ? 'd.' : 'd';
        const monthYearFormat = 'MMM yyyy';
        const monthFormat = 'MMM';

        if (position === 'start') {
            if (!sameYear) {
                // Different years - show full date with year
                return format(start, `${weekdayFormat}, ${dayFormat} ${monthYearFormat}`, {
                    locale: $utils.getDateFnsLocale(),
                });
            } else {
                return format(start, `${weekdayFormat}, ${dayFormat} ${monthFormat}`, {
                    locale: $utils.getDateFnsLocale(),
                });
            }
        } else {
            // position === 'end'
            return format(end, `${weekdayFormat}, ${dayFormat} ${monthYearFormat}`, {
                locale: $utils.getDateFnsLocale(),
            });
        }
    };
}
