import Vue from 'vue';
import { parsePhoneNumber } from 'libphonenumber-js/mobile';
import { helpers } from 'vuelidate/lib/validators';
import { enGB, sv, fi, nb, da, frCH, de } from 'date-fns/locale';
import parseISO from 'date-fns/parseISO';
import getMonth from 'date-fns/getMonth';
import isValid from 'date-fns/isValid';
import isFuture from 'date-fns/isFuture';
import subYears from 'date-fns/subYears';
import differenceInDays from 'date-fns/differenceInDays';
import isWithinInterval from 'date-fns/isWithinInterval';
import { swissCountryCodes } from '~/config/countryCodeLists';
import featureFlagsMixin from '~~/mixins/featureFlagsMixin';
import { actionTypes as navigationActions } from '~/store/navigation';
import formattingHelpersMixin from '~/mixins/formattingHelpersMixin';

import AdditionalDriverService from '~/services/AdditionalDriverService';
import AssetDamageService from '~/services/AssetDamageService';
import AuthService from '~/services/AuthService';
import BookingChangeRequestService from '~/services/BookingChangeRequestService';
import BookingService from '~/services/BookingService';
import CmsService from '~/services/CmsService';
import ConfigService from '~/services/ConfigService';
import ConversationService from '~/services/ConversationService';
import CriiptoService from '~/services/CriiptoService';
import InstallmentService from '~/services/InstallmentService';
import DepositService from '~/services/DepositService';
import LeadService from '~/services/LeadService';
import MeService from '~/services/MeService';
import OrderService from '~/services/OrderService';
import PageService from '~/services/PageService';
import PaymentService from '~/services/PaymentService';
import PayoutService from '~/services/PayoutService';
import PriceCalculationsService from '~/services/PriceCalculationsService';
import ProductService from '~/services/ProductService';
import StripeService from '~/services/StripeService';
import UserService from '~/services/UserService';
import UserTermsService from '~/services/UserTermsService';
import VehicleService from '~/services/VehicleService';
import VerifyService from '~/services/VerifyService';

import Closable from '~/directives/Closable';
import SelectOverflow from '~/directives/SelectOverflow';
import LazyLoadImage from '~/directives/LazyLoadImage';
import NearViewportFoldDetection from '~/directives/NearViewportFoldDetection';

import LocalizedLink from '~/components/LocalizedLink.vue';
import {
    dateFormatFilter,
    datetimeFormatFilter,
    fullDateFormatFilter,
    compactDateRangeFormatFilter,
} from '~/filters/dateFormatFilter';
import percentageFilter from '~/filters/percentageFilter';
import currencyUnitFilter from '~/filters/currencyUnitFilter';
import { formatAsNumberFilter, formatAsPriceFilter, priceFilter, roundedFilter } from '~/filters/pricingFilters';
import { setupVehicleFields } from '~/config/vehicleFields';
import { ASSET_SUB_TYPE_CARAVAN, ASSET_SUB_TYPE_CAMPERVAN, ASSET_SUB_TYPE_MOTORHOME } from '~/config/assetTypes';
import { BALOISE_INSURANCE_LIMITS } from '~/config/insurance';
import { INSURANCE_ADDON } from '~/config/productTypes';

export default function (context, inject) {
    setupVehicleFields(context);

    const isDateHighSeason = dateStr => {
        // Months are indexed with January being 0
        const JS_MONTH_JUNE = 5;
        const JS_MONTH_AUGUST = 7;

        const date = parseISO(dateStr);
        const month = getMonth(date);

        return month >= JS_MONTH_JUNE && month <= JS_MONTH_AUGUST;
    };

    const defaultDateFormat = () => {
        switch (context.i18n.locale) {
            case 'se':
            case 'fi-sv':
                return 'yyyy-MM-dd';
            case 'fi':
            case 'no':
            case 'de':
            case 'dk':
            case 'ch':
                return 'dd.MM.yyyy';
            case 'en':
            default:
                return 'dd/MM/yyyy';
        }
    };

    inject('utils', {
        defaultDateFormat,
        isDateHighSeason,
        isOnlyHighSeason: (from, to) => isDateHighSeason(from) && isDateHighSeason(to),
        isOnlyLowSeason: (from, to) => !isDateHighSeason(from) && !isDateHighSeason(to),
        sortCountriesByLabel: (a, b) => {
            const aLabel = context.i18n.t(`localization.countries.${a}`).toUpperCase();
            const bLabel = context.i18n.t(`localization.countries.${b}`).toUpperCase();

            return aLabel.localeCompare(bLabel, context.i18n.locale);
        },
        getDateFnsLocale() {
            const dateFnsLocalePlaceholder = {
                se: sv,
                fi,
                'fi-sv': sv,
                no: nb,
                en: enGB,
                de,
                dk: da,
                ch: de,
                fr: frCH,
            };

            return dateFnsLocalePlaceholder[context.i18n.locale]
                ? dateFnsLocalePlaceholder[context.i18n.locale]
                : 'en-GB';
        },
        renterCanNotBookBaloiseVehicle(vehicle) {
            if (!vehicle) {
                return true;
            }

            const { user, isRenter, userCanBuyBaloiseInsurance } = context.store.getters;
            const isBaloiseInsurance = vehicle.hasPlatformInsurance && vehicle.isConsideredSwiss;
            const assetCountryAllowedByBaloise = BALOISE_INSURANCE_LIMITS.allowedAssetCountryCodes.includes(
                vehicle.country
            );
            const hasDeductibleInsuranceProduct =
                vehicle.mandatoryAddons && vehicle.mandatoryAddons[INSURANCE_ADDON.deductible_insurance];
            const hasInteriorInsuranceProduct =
                vehicle.mandatoryAddons && vehicle.mandatoryAddons[INSURANCE_ADDON.interior_insurance];
            const vehicleHasBaloiseProduct =
                isBaloiseInsurance ||
                (assetCountryAllowedByBaloise && (hasDeductibleInsuranceProduct || hasInteriorInsuranceProduct));

            return user?.country && isRenter && !userCanBuyBaloiseInsurance && vehicleHasBaloiseProduct;
        },
        getPlatformCountry(country) {
            return swissCountryCodes.includes(country) ? 'CH' : country;
        },
        getVehicleSearchPagePath(vehicle) {
            const { subType } = vehicle;
            let path;

            switch (subType) {
                case ASSET_SUB_TYPE_MOTORHOME:
                    path = context.i18n.t('common.motorhomes_page_path');
                    break;
                case ASSET_SUB_TYPE_CAMPERVAN:
                    path = context.i18n.t('common.campervans_page_path');
                    break;
                case ASSET_SUB_TYPE_CARAVAN:
                    path = context.i18n.t('common.caravans_page_path');
                    break;
                default:
                    path = context.i18n.t('common.motorhomes_page_path');
            }

            path = `/${path}`;

            return path;
        },
        vehicleCanHaveBaloiseInsurance(vehicle) {
            return vehicle.isConsideredSwiss && vehicle.countryAllowsPlatformInsurance;
        },
        /**
         * Return array that is sorted based on the order of another array
         *
         * @param {array} arr
         * @param {array} order
         * @param {string} key
         */
        mapOrder(arr, order, key) {
            if (!arr || !order || !key) {
                return arr;
            }

            // Create a map of order indices for quick lookup
            const map = order.reduce((r, value, i) => {
                r[value] = i;

                return r;
            }, {});

            // Sort the source array based on the order indices
            const sortedArray = arr.slice().sort((x, y) => {
                const indexX = map[x[key]] !== undefined ? map[x[key]] : order.length;
                const indexY = map[y[key]] !== undefined ? map[y[key]] : order.length;

                return indexX - indexY;
            });

            return sortedArray;
        },
        isVisibleInViewport(elementId, offsetParam) {
            const offset = offsetParam || 0;
            let elem = document.getElementById(elementId);

            if (!elem || !window) {
                return false;
            }

            let y = elem.offsetTop;
            const height = elem.offsetHeight;

            while ((elem = elem.offsetParent)) {
                y += elem.offsetTop;
            }

            const maxHeight = y + height;

            if (y < window.scrollY + window.innerHeight + offset && maxHeight >= window.scrollY + offset) {
                return true;
            } else {
                return false;
            }
        },
        vehicleCardPrice(vehicle, selectedDates) {
            const { totalPrice } = vehicle;

            const hasSelectedDates = selectedDates?.from && selectedDates?.to;

            if (totalPrice) {
                return hasSelectedDates ? priceFilter(totalPrice) : priceFilter(totalPrice / 7);
            }

            return 0;
        },
        /**
         * Util to safely base64-encode UTF-8 string
         *
         * This solves a problem with using åäö characters in the signing text
         *
         * Inspiration: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#Solution_4_%E2%80%93_escaping_the_string_before_encoding_it
         *
         * @param {string} str String to encode
         * @returns {string} The str parameter, base64 encoded
         */
        base64EncodeUnicode: str => {
            // first we use encodeURIComponent to get percent-encoded UTF-8,
            // then we convert the percent encodings into raw bytes which
            // can be fed into btoa.
            const uriEncoded = encodeURIComponent(str);
            const bytes = uriEncoded.replace(/%([0-9A-F]{2})/g, (match, p1) => {
                /* istanbul ignore next */
                return String.fromCharCode(`0x${p1}`);
            });

            return btoa(bytes);
        },
    });

    /**
     * API services
     */
    inject('additionalDriverService', new AdditionalDriverService(context));
    inject('assetDamageService', new AssetDamageService(context));
    inject('authService', new AuthService(context));
    inject('bookingChangeRequestService', new BookingChangeRequestService(context));
    inject('bookingService', new BookingService(context));
    inject('cmsService', new CmsService(context));
    inject('configService', new ConfigService(context));
    inject('conversationService', new ConversationService(context));
    inject('criiptoService', new CriiptoService(context));
    inject('installmentService', new InstallmentService(context));
    inject('depositService', new DepositService(context));
    inject('leadService', new LeadService(context));
    inject('meService', new MeService(context));
    inject('orderService', new OrderService(context));
    inject('paymentService', new PaymentService(context));
    inject('payoutService', new PayoutService(context));
    inject('priceCalculationsService', new PriceCalculationsService(context));
    inject('pageService', new PageService(context));
    inject('productService', new ProductService(context));
    inject('stripeService', new StripeService(context));
    inject('userService', new UserService(context));
    inject('userTermsService', new UserTermsService(context));
    inject('vehicleService', new VehicleService(context));
    inject('verifyService', new VerifyService(context));

    if (!Vue.__mc_globals__) {
        Vue.__mc_globals__ = true;

        Vue.mixin(featureFlagsMixin);
        Vue.mixin(formattingHelpersMixin);

        Vue.filter('currencyUnit', currencyUnitFilter(context));
        Vue.filter('dateFormat', dateFormatFilter(context));
        Vue.filter('datetimeFormat', datetimeFormatFilter(context));
        Vue.filter('fullDateFormat', fullDateFormatFilter(context));
        Vue.filter('compactDateRangeFormat', compactDateRangeFormatFilter(context));
        Vue.filter('formatAsPrice', formatAsPriceFilter(context));
        Vue.filter('formatAsNumber', formatAsNumberFilter(context));

        Vue.filter('percentage', percentageFilter);
        Vue.filter('price', priceFilter);
        Vue.filter('rounded', roundedFilter);

        Vue.directive('closable', Closable);
        Vue.directive('select-overflow', SelectOverflow);
        Vue.directive('lazy-load-image', LazyLoadImage);
        Vue.directive('near-viewport-fold-detection', NearViewportFoldDetection);

        Vue.component('LocalizedLink', LocalizedLink);
    }

    const getDateDiff = (from, to) => {
        return Math.abs(differenceInDays(parseISO(from), parseISO(to)));
    };

    inject('validators', {
        isValidRange(invalidDates, from, to) {
            if (!invalidDates || invalidDates.length === 0 || !from || !to) {
                return true;
            }

            const interval = { start: parseISO(from), end: parseISO(to) };

            return invalidDates.every(
                date => date !== from && date !== to && !isWithinInterval(parseISO(date), interval)
            );
        },

        isRangeShorterThanMax(from, to) {
            if (from === null || to === null) {
                return true;
            }

            return getDateDiff(from, to) <= 90;
        },

        isRangeLongEnough(minLength, from, to) {
            if (from === null || to === null) {
                return true;
            }

            return getDateDiff(from, to) >= minLength;
        },

        isValidPhoneNumber(value, countryCode) {
            if (!helpers.req(value)) {
                return true;
            }

            if (countryCode === 'UK') {
                countryCode = 'GB';
            }

            try {
                const phoneNumber = parsePhoneNumber(value, countryCode);

                return phoneNumber.isValid();
            } catch (e) {
                return false;
            }
        },

        isValidSupportedPostalCode(value) {
            if (!helpers.req(value)) {
                return true;
            }

            const normalized = `${value}`.replace('-', '').toLowerCase();

            return /^((s|se|n|no|dk|f|fi)\s?){0,1}[0-9]{2,3}\s?[0-9]{2}$/.test(normalized);
        },

        isValidPassword(value) {
            if (!helpers.req(value)) {
                return true;
            }

            const exp = /(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/;

            return exp.test(value);
        },

        isValidBirthday(value) {
            // Make sure this is in sync with internal validation of BirthdayInput.vue
            if (!value) {
                return false;
            }

            const date = parseISO(value);

            if (!isValid(date)) {
                return false;
            }

            const year = date.getFullYear();

            return !isFuture(date) && date <= subYears(new Date(), 18) && year >= 1900;
        },
    });

    context.app.router.beforeEach((to, from, next) => {
        if (!from.name) {
            // This is the first route change, so we don't need to clear anything
            next();

            return;
        }

        if (to.path !== from.path) {
            context.store.dispatch(navigationActions.CLEAR_ACTIVE_MENU_ITEM);
            context.store.dispatch(navigationActions.CLEAR_ROUTE_PARAMS);
        }

        if (to.name.includes('owner-complete-profile')) {
            const fromName = from.name.split('__')[0];
            sessionStorage.setItem('previousRoute', fromName);
        }

        next();
    });
}
