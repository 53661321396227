import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['isLoggedIn', 'user']),
    },
    methods: {
        setPersistence() {
            const hasConsent = window.Cookiebot?.consent?.statistics;
            this.$posthog.set_config({ persistence: hasConsent ? 'localStorage+cookie' : 'memory' });
        },
        identifyUser() {
            this.$posthog.identify(this.user.id, {
                userType: this.user.role,
                country: this.user.country || this.$meService.getUserCountryBasedOnTimezone(),
            });
        },
    },
    mounted() {
        if (this.isLoggedIn) {
            this.identifyUser();
        }

        window.addEventListener('CookiebotOnAccept', this.setPersistence);
        window.addEventListener('CookiebotOnDecline', this.setPersistence);
    },
    beforeDestroy() {
        window.removeEventListener('CookiebotOnAccept', this.setPersistence);
        window.removeEventListener('CookiebotOnDecline', this.setPersistence);
    },
    watch: {
        isLoggedIn(newValue) {
            if (newValue) {
                this.identifyUser();
            } else {
                this.$posthog.reset();
            }
        },
    },
};
