/* eslint-disable no-param-reassign, no-shadow */
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import parseISO from 'date-fns/parseISO';
import { ASSET_SUB_TYPE_MOTORHOME, ASSET_SUB_TYPE_CAMPERVAN, ASSET_SUB_TYPE_OTHER_CAMPER } from '~/config/assetTypes';
import {
    DRAFT,
    PENDING_CONFIRMATION,
    CONFIRMED,
    ACTIVE,
    ENDED,
    COMPLETED,
    CANCELED_BY_OWNER,
    CANCELED_BY_RENTER,
    CANCELED_BY_SYSTEM,
} from '~/config/bookingStatuses';
import { OPEN, PENDING_OWNER_CONFIRMATION } from '~/config/orderStatuses';
import { REQUESTED, ACCEPTED, DENIED } from '~/config/changeRequestStatuses';
import { automaticCategories, DAMAGE, EQUIPMENT, EXTRA_DISTANCE, FUEL, ADDON } from '~/config/extraChargeCategories';
import { INSURANCE_ADDON } from '~/config/productTypes';
import BookingService from '~/services/BookingService';
import { actionTypes as orderActions } from '~/store/order';
import { BALOISE, OMOCOM } from '~/config/insurance';
import { NON_BINDING, INSTANT, BINDING } from '~/config/bookingMethods';

export const mutationTypes = {
    BOOKING_SET: 'BOOKING/BOOKING_SET',
    BOOKING_UNSET: 'BOOKING/BOOKING_UNSET',
    DRIVER_SET: 'BOOKING/DRIVER_SET',
    INSURANCE_POLICY_SET: 'BOOKING/INSURANCE_POLICY_SET',
    AVAILABLE_ADDONS_SET: 'BOOKING/AVAILABLE_ADDONS_SET',
    SET_CANCELLATION_NOTICE_BOOKING_MENU_VISIBLE: 'BOOKING/SET_CANCELLATION_NOTICE_BOOKING_MENU_VISIBLE',
};

export const actionTypes = {
    FETCH_BOOKING: 'BOOKING/FETCH_BOOKING',
    SAVE_BOOKING: 'BOOKING/SAVE_BOOKING',
    SET_BOOKING: 'BOOKING/SET_BOOKING',
    CREATE_REVIEW: 'BOOKING/CREATE_REVIEW',
    SET_DRIVER: 'BOOKING/SET_DRIVER',
    FETCH_INSURANCE_POLICY: 'BOOKING/FETCH_INSURANCE_POLICY',
    FETCH_AVAILABLE_ADDONS: 'BOOKING/FETCH_AVAILABLE_ADDONS',
    SET_CANCELLATION_NOTICE_BOOKING_MENU_VISIBLE: 'BOOKING/SET_CANCELLATION_NOTICE_BOOKING_MENU_VISIBLE',
};

export const state = () => ({
    booking: null,
    currentDriver: null,
    insurancePolicy: null,
    availableAddons: null,
    cancellationNoticeBookingMenuVisible: false,
});

export const getters = {
    booking: state => state.booking,
    currentDriver: state => (state.currentDriver ? state.currentDriver : null),
    insurancePolicy: state => state.insurancePolicy,
    availableAddons: state => state.availableAddons,
    bookingIsOpen: state =>
        state.booking && [PENDING_CONFIRMATION, CONFIRMED, ACTIVE].includes(state.booking.ecommerceStatus),
    bookingAwaitingOwnerAccept: state =>
        state.booking?.order?.status === PENDING_OWNER_CONFIRMATION || state.booking?.order?.status === DRAFT,
    bookingAcceptedButNotConfirmed: state =>
        state.booking?.ecommerceStatus === PENDING_CONFIRMATION && state.booking?.order?.status === OPEN,
    bookingAssetId: state => state.booking?.assetId,
    bookingHasBeenConfirmed: state => {
        return !!state.booking?.confirmedAt;
    },
    bookingIsPending: state => state.booking?.ecommerceStatus === PENDING_CONFIRMATION,
    bookingIsConfirmed: state => state.booking?.ecommerceStatus === CONFIRMED,
    bookingIsActive: state => state.booking?.ecommerceStatus === ACTIVE,
    bookingIsEnded: state => state.booking?.ecommerceStatus === ENDED,
    bookingIsCompleted: state => state.booking?.ecommerceStatus === COMPLETED,
    bookingIsCanceledByOwner: state => state.booking?.ecommerceStatus === CANCELED_BY_OWNER,
    bookingIsCanceledByRenter: state => state.booking?.ecommerceStatus === CANCELED_BY_RENTER,
    bookingIsCanceledBySystem: state => state.booking?.ecommerceStatus === CANCELED_BY_SYSTEM,
    bookingIsCanceled: state => {
        return (
            state.booking &&
            [CANCELED_BY_OWNER, CANCELED_BY_RENTER, CANCELED_BY_SYSTEM].includes(state.booking.ecommerceStatus)
        );
    },
    bookingAllowsRenterCancellation: (_state, getters) =>
        getters.bookingIsOpen && !getters.bookingIsActive && !getters.bookingAcceptedButNotConfirmed,
    isInstantBooking: state => state.booking?.assetSnapshot?.bookingMethod === INSTANT,
    isBindingBooking: state => state.booking?.assetSnapshot?.bookingMethod === BINDING,
    withDownPayment: state =>
        state.booking?.downPaymentPercentage !== null && state.booking?.downPaymentPercentage < 0.99,
    isOmocomInsurance: state => {
        if (!state.booking?.insuranceVendor) {
            return false;
        }

        return state.booking.insuranceVendor.name?.toLowerCase() === OMOCOM;
    },
    isBaloiseInsurance: state => {
        if (!state.booking?.insuranceVendor) {
            return false;
        }

        return state.booking.insuranceVendor.name?.toLowerCase() === BALOISE;
    },
    bookingAssetCountry: state => state.booking?.assetSnapshot?.country,
    bookingHasDelayedInitialPayment: state =>
        state.booking?.initialPaymentAfterAccept || state.booking?.assetSnapshot?.bookingMethod === NON_BINDING,
    additionalPaymentsCanBeImmediatellyCaptured: (state, getters) => {
        if (!state.booking) {
            return false;
        }

        if (getters.bookingHasDelayedInitialPayment) {
            // All payments on 'pay later bookings' can be captured immediately
            return true;
        }

        if ([DRAFT, PENDING_CONFIRMATION].includes(state.booking?.ecommerceStatus)) {
            return false;
        }

        return true;
    },
    hasDeductibleBuydown: state => {
        if (!state.booking || !state.booking?.includedAddons) {
            return false;
        }

        return state.booking.includedAddons.includes(INSURANCE_ADDON.deductible_buydown);
    },
    bookingStarted: state => {
        if (!state.booking) {
            return false;
        }

        const diff = differenceInMilliseconds(parseISO(state.booking.checkin), new Date());

        return diff < 0;
    },
    bookingAllowsDeductibleBuydownPurchase: (state, getters) => {
        if (!state.booking || ![PENDING_CONFIRMATION, CONFIRMED].includes(state.booking.ecommerceStatus)) {
            return false;
        }

        const { hasPlatformInsurance } = state.booking;

        return hasPlatformInsurance && !getters.hasDeductibleBuydown && !getters.bookingStarted;
    },
    bookingAllowsAdditionalDriver: (state, getters) => {
        if (!state.booking || !getters.bookingIsOpen || getters.bookingIsActive || getters.bookingStarted) {
            return false;
        }

        return true;
    },
    bookingPendingChangeRequest: state => {
        if (!state.booking || !Array.isArray(state.booking.changeRequests)) {
            return null;
        }

        const match = state.booking.changeRequests.find(changeRequest => changeRequest.status === REQUESTED);

        if (!match) {
            // Undefined if no match found
            return null;
        }

        return match;
    },
    lastAcceptedChangeRequest: state => {
        if (!state.booking || !Array.isArray(state.booking.changeRequests) || !state.booking.changeRequests.length) {
            return null;
        }

        const lastItemIndex = state.booking.changeRequests.length - 1;

        if (state.booking.changeRequests[lastItemIndex].status === ACCEPTED) {
            return state.booking.changeRequests[lastItemIndex];
        }

        return null;
    },
    lastDeniedChangeRequest: state => {
        if (!state.booking || !Array.isArray(state.booking.changeRequests) || !state.booking.changeRequests.length) {
            return null;
        }

        const lastItemIndex = state.booking.changeRequests.length - 1;

        if (state.booking.changeRequests[lastItemIndex].status === DENIED) {
            return state.booking.changeRequests[lastItemIndex];
        }

        return null;
    },
    isCashDepositWithoutHoldDeposit: state => {
        return state.booking.hasCashDeposit && !state.booking.holdDeposit && !state.booking.hasPendingAmountDamages;
    },
    ownerCheckinOpen: (state, getters) => {
        if (!state.booking) {
            return false;
        }

        if (state.booking.hasCashDeposit) {
            return (
                state.booking.isCheckinOpen ||
                (getters.checkinData && !getters.checkinData.ownerConfirmedCashDepositPaidAt)
            );
        }

        return state.booking.isCheckinOpen;
    },
    ownerCheckinDone: (state, getters) => {
        if (!state.booking) {
            return false;
        }

        if (state.booking.hasCashDeposit) {
            return !!getters.checkinData?.ownerConfirmedCashDepositPaidAt;
        }

        return state.booking.ownerCheckinDone;
    },
    ownerCheckoutOpen: (state, getters) => {
        if (!getters.bookingIsActive) {
            return false;
        }

        if (getters.isCashDepositWithoutHoldDeposit) {
            return (
                !getters.checkoutDone ||
                (getters.checkoutData && !getters.checkoutData.renterConfirmedCashDepositRefundedAt)
            );
        }

        return !getters.checkoutDone && getters.ownerCheckinDone;
    },
    ownerCheckoutDone: (state, getters) => {
        if (!state.booking) {
            return false;
        }

        if (getters.isCashDepositWithoutHoldDeposit) {
            return !!getters.checkoutData?.renterConfirmedCashDepositRefundedAt;
        }

        return state.booking.ownerCheckoutDone;
    },
    renterCheckinOpen: (state, getters) => {
        if (!state.booking || !getters.checkinData) {
            return false;
        }

        if (state.booking.hasCashDeposit) {
            return (
                state.booking.ownerCheckinDone &&
                getters.checkinData &&
                !getters.checkinData.ownerConfirmedCashDepositPaidAt
            );
        }

        return getters.ownerCheckinDone && !getters.checkinDone;
    },
    renterCheckoutOpen: (state, getters) => {
        if (!state.booking || !getters.checkoutData) {
            return false;
        }

        if (getters.isCashDepositWithoutHoldDeposit) {
            return (
                state.booking.ownerCheckoutDone &&
                getters.checkoutData &&
                !getters.checkoutData.renterConfirmedCashDepositRefundedAt
            );
        }

        return getters.ownerCheckoutDone && !getters.checkoutDone;
    },
    checkinDone: (state, getters) => {
        if (!state.booking) {
            return false;
        }

        if (state.booking.hasCashDeposit) {
            return !!getters.checkinData?.ownerConfirmedCashDepositPaidAt;
        }

        return state.booking.checkinApprovedAt;
    },
    checkoutDone: (state, getters) => {
        if (!state.booking) {
            return false;
        }

        if (getters.isCashDepositWithoutHoldDeposit) {
            return !!getters.checkoutData?.renterConfirmedCashDepositRefundedAt;
        }

        return getters.bookingIsCompleted || state.booking.checkoutApprovedAt;
    },
    checkinDamages: state => {
        if (!state.booking || !Array.isArray(state.booking.checkinDamages)) {
            return [];
        }

        return state.booking.checkinDamages;
    },
    checkoutDamages: state => {
        if (!state.booking || !Array.isArray(state.booking.checkoutDamages)) {
            return [];
        }

        return state.booking.checkoutDamages;
    },
    hasPendingAmountDamages: (_state, getters) => {
        return getters.booking.hasPendingAmountDamages;
    },
    hasInsuranceClaimDamages: (_state, getters) => {
        return getters.booking.hasInsuranceClaimDamages;
    },
    checkinData: state => {
        if (!state.booking || !state.booking.checkinData) {
            return null;
        }

        return { ...state.booking.checkinData };
    },
    checkoutData: state => {
        if (!state.booking || !state.booking.checkoutData) {
            return null;
        }

        return { ...state.booking.checkoutData };
    },
    checkinImages: state => {
        if (!state.booking || !state.booking.checkinImages) {
            return null;
        }

        return { ...state.booking.checkinImages };
    },
    checkoutImages: state => {
        if (!state.booking || !state.booking.checkoutImages) {
            return null;
        }

        return { ...state.booking.checkoutImages };
    },
    isBookingVehicleMotorized: state => {
        if (!state.booking || !state.booking.assetSnapshot) {
            return false;
        }

        const { assetSnapshot } = state.booking;

        return (
            !assetSnapshot.subType ||
            assetSnapshot.subType === ASSET_SUB_TYPE_MOTORHOME ||
            assetSnapshot.subType === ASSET_SUB_TYPE_CAMPERVAN ||
            (assetSnapshot.subType === ASSET_SUB_TYPE_OTHER_CAMPER && assetSnapshot.isMotorized)
        );
    },
    isBookingVehicleHeavy: state => {
        if (!state.booking || !state.booking.assetSnapshot) {
            return false;
        }

        const { assetSnapshot } = state.booking;

        return assetSnapshot.maximumAuthorizedMass > 3500;
    },
    extraDistanceCostAmount: (_state, getters) => {
        if (!getters.extraDistanceCharge) {
            return 0;
        }

        return getters.extraDistanceCharge.amount;
    },
    extraDistanceCostAmountIncFee: (_state, getters) => {
        if (!getters.extraDistanceCharge) {
            return 0;
        }

        return getters.extraDistanceCharge.amountIncFee;
    },
    extraCharges: state => (state.booking ? state.booking.extraCharges || [] : []),
    extraChargeDiscounts: state => (state.booking ? state.booking.extraChargeDiscounts || [] : []),
    customExtraCharges: (_state, getters) => {
        if (!getters.extraCharges) {
            return [];
        }

        return getters.extraCharges.filter(c => !automaticCategories.includes(c.category));
    },
    equipmentExtraCharges: (_state, getters) => {
        if (!getters.extraCharges) {
            return [];
        }

        return getters.extraCharges.filter(c => c.category === EQUIPMENT);
    },
    addonExtraCharges: (_state, getters) => {
        if (!getters.extraCharges) {
            return [];
        }

        return getters.extraCharges.filter(c => c.category === ADDON);
    },
    damageExtraCharges: (_state, getters) => {
        if (!getters.extraCharges) {
            return [];
        }

        return getters.extraCharges.filter(c => c.category === DAMAGE);
    },
    fuelExtraCharges: (_state, getters) => {
        if (!getters.extraCharges) {
            return [];
        }

        return getters.extraCharges.filter(c => c.category === FUEL);
    },
    extraDistanceCharge: (_state, getters) => {
        if (!getters.extraCharges) {
            return null;
        }

        return getters.extraCharges.find(c => c.category === EXTRA_DISTANCE);
    },
    extraChargesTotalAmount: (_state, getters) => {
        if (!getters.extraCharges) {
            return 0;
        }

        const chargesAmount = getters.extraCharges
            .map(charge => charge.amount)
            .reduce((total, amount) => total + amount, 0);

        return Math.max(0, chargesAmount - getters.extraChargeDiscountsTotalAmount);
    },
    extraChargesTotalAmountIncFee: (_state, getters) => {
        if (!getters.extraCharges) {
            return 0;
        }

        return getters.extraChargesTotalAmount * (1 + getters.booking.extraChargesServiceFeePercentage);
    },
    extraChargesServiceFeeAmount: (_state, getters) => {
        if (!getters.extraCharges) {
            return 0;
        }

        return getters.extraChargesTotalAmount * getters.booking.extraChargesServiceFeePercentage;
    },
    extraChargeDiscountsTotalAmount: (_state, getters) => {
        if (!getters.extraChargeDiscounts) {
            return 0;
        }

        return getters.extraChargeDiscounts.map(charge => charge.amount).reduce((total, amount) => total + amount, 0);
    },
    damageExtraChargeAmount: (_state, getters) => {
        if (!getters.damageExtraCharges) {
            return 0;
        }

        return getters.damageExtraCharges.map(charge => charge.amount).reduce((total, amount) => total + amount, 0);
    },
    damageExtraChargeAmountIncFee: (_state, getters) => {
        if (!getters.damageExtraCharges) {
            return 0;
        }

        return getters.damageExtraCharges
            .map(charge => charge.amountIncFee)
            .reduce((total, amount) => total + amount, 0);
    },
    omocomClaimLink: (_state, getters) => {
        let claimLink = 'https://external.omocom.se/claimsform';
        const { booking, isRenter } = getters;

        if (!booking || !booking.insuranceId) {
            return claimLink;
        }

        if (isRenter && booking.renter.insuranceId) {
            claimLink += `?insuranceId=${booking.insuranceId}&userHandle=${booking.renter.insuranceId}`;
        } else if (booking.owner.insuranceId) {
            claimLink += `?insuranceId=${booking.insuranceId}&userHandle=${booking.owner.insuranceId}`;
        }

        return claimLink;
    },
    baloiseClaimLink: locale => {
        let claimLink = 'https://ask.baloise.ch/s/mycamperen';

        if (locale === 'de' || locale === 'ch') {
            claimLink = 'https://ask.baloise.ch/s/mycamperde';
        } else if (locale === 'fr') {
            claimLink = 'https://ask.baloise.ch/s/mycamperfr';
        }

        return claimLink;
    },
    cancellationNoticeBookingMenuVisible: state => state.cancellationNoticeBookingMenuVisible,
    requiresIdVerificationBeforeCheckin: (state, rootGetters) => {
        if (!state.booking || !rootGetters.user) {
            return false;
        }

        if (rootGetters.user.isIdVerified) {
            return false;
        }

        const userIsSwiss = rootGetters.user.isConsideredSwiss;
        const assetIsSwiss = state.booking.assetSnapshot?.isConsideredSwiss;

        if (rootGetters.isOwner) {
            return !userIsSwiss;
        }

        return rootGetters.isRenter && !(userIsSwiss && assetIsSwiss);
    },
};

export const mutations = {
    [mutationTypes.BOOKING_SET](state, payload) {
        state.booking = { ...payload };
    },
    [mutationTypes.BOOKING_UNSET](state) {
        state.booking = null;
    },
    [mutationTypes.DRIVER_SET](state, payload) {
        state.currentDriver = payload;
    },
    [mutationTypes.INSURANCE_POLICY_SET](state, payload) {
        state.insurancePolicy = payload;
    },
    [mutationTypes.AVAILABLE_ADDONS_SET](state, payload) {
        state.availableAddons = payload;
    },
    [mutationTypes.SET_CANCELLATION_NOTICE_BOOKING_MENU_VISIBLE](state, isVisible) {
        state.cancellationNoticeBookingMenuVisible = isVisible;
    },
};

export const actions = {
    [actionTypes.FETCH_BOOKING]({ commit, dispatch }, id) {
        return new BookingService(this)
            .getBooking(id)
            .then(booking => {
                commit(mutationTypes.BOOKING_SET, booking);

                return booking;
            })
            .then(booking => {
                // Also always (re)fetch the order when fetching the booking
                if (booking.order) {
                    return dispatch(orderActions.FETCH, booking.order.id).then(() => {
                        return booking;
                    });
                }

                return booking;
            });
    },
    [actionTypes.SAVE_BOOKING]({ commit, state }, data) {
        return new BookingService(this).updateBooking(state.booking.id, data).then(booking => {
            commit(mutationTypes.BOOKING_SET, booking);
        });
    },
    [actionTypes.SET_BOOKING]({ commit }, booking) {
        commit(mutationTypes.BOOKING_SET, booking);
    },
    [actionTypes.CREATE_REVIEW]({ state, dispatch }, reviewData) {
        return new BookingService(this)
            .createReview(state.booking.id, reviewData.overallScore, reviewData.overallComment)
            .then(() => {
                return dispatch(actionTypes.FETCH_BOOKING, state.booking.id);
            });
    },
    [actionTypes.SET_DRIVER]({ commit }, driver) {
        commit(mutationTypes.DRIVER_SET, driver);
    },
    [actionTypes.FETCH_INSURANCE_POLICY]({ state, commit }) {
        return new BookingService(this).getInsurancePolicy(state.booking.id).then(policy => {
            commit(mutationTypes.INSURANCE_POLICY_SET, policy);
        });
    },
    [actionTypes.FETCH_AVAILABLE_ADDONS]({ state, commit }) {
        return new BookingService(this).getAvailableAddons(state.booking.id).then(addons => {
            commit(mutationTypes.AVAILABLE_ADDONS_SET, addons);
        });
    },
    [actionTypes.SET_CANCELLATION_NOTICE_BOOKING_MENU_VISIBLE]({ commit }, isVisible) {
        commit(mutationTypes.SET_CANCELLATION_NOTICE_BOOKING_MENU_VISIBLE, isVisible);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
    mutationTypes,
};
