/* eslint-disable no-param-reassign, no-shadow */
import { actionTypes as myVehiclesActionTypes } from './myVehicles';
import {
    ASSET_SUB_TYPE_MOTORHOME,
    ASSET_SUB_TYPE_CAMPERVAN,
    ASSET_SUB_TYPE_CARAVAN,
    ASSET_SUB_TYPE_OTHER_CAMPER,
} from '~/config/assetTypes';
import { OMOCOM_INSURANCE_LIMITS } from '~/config/insurance';
import VehicleService from '~/services/VehicleService';

// Number of milliseconds to display the saved message
export const SAVE_MESSAGE_TIMEOUT = 6 * 1000;

export const mutationTypes = {
    VEHICLE_SET: 'EDIT_VEHICLE/VEHICLE_SET',
    VEHICLE_UNSET: 'EDIT_VEHICLE/VEHICLE_UNSET',

    IMAGES_SET: 'EDIT_VEHICLE/IMAGES_SET',
    IMAGES_UNSET: 'EDIT_VEHICLE/IMAGES_UNSET',
    LOADING_IMAGES_SET: 'EDIT_VEHICLE/LOADING_IMAGES_SET',

    SAVED_MESSAGE_SET: 'EDIT_VEHICLE/SAVED_MESSAGE_SET',
    SAVED_MESSAGE_CLEAR: 'EDIT_VEHICLE/SAVED_MESSAGE_CLEAR',

    UNAVAILABLE_PERIODS_SET: 'EDIT_VEHICLE/UNAVAILABLE_PERIODS_SET',
    UNAVAILABLE_PERIODS_CLEAR: 'EDIT_VEHICLE/UNAVAILABLE_PERIODS_CLEAR',

    RENTED_PERIODS_SET: 'EDIT_VEHICLE/RENTED_PERIODS_SET',
    RENTED_PERIODS_CLEAR: 'EDIT_VEHICLE/RENTED_PERIODS_CLEAR',
};

export const actionTypes = {
    CLEAR: 'EDIT_VEHICLE/CLEAR',

    DELETE_REGISTRATION_DOCUMENT: 'EDIT_VEHICLE/DELETE_REGISTRATION_DOCUMENT',
    FETCH_VEHICLE: 'EDIT_VEHICLE/FETCH_VEHICLE',
    RELOAD_CURRENT_VEHICLE: 'EDIT_VEHICLE/RELOAD_CURRENT_VEHICLE',
    SET_CURRENT_VEHICLE: 'EDIT_VEHICLE/SET_CURRENT_VEHICLE',
    SET_REGISTRATION_DOCUMENT: 'EDIT_VEHICLE/SET_REGISTRATION_DOCUMENT',
    SAVE_CURRENT_VEHICLE: 'EDIT_VEHICLE/SAVE_CURRENT_VEHICLE',
    CONFIRM_INSURANCE_REQUIREMENTS: 'EDIT_VEHICLE/CONFIRM_INSURANCE_REQUIREMENTS',

    FETCH_IMAGES: 'EDIT_VEHICLE/FETCH_IMAGES',
    UPDATE_IMAGES: 'EDIT_VEHICLE/UPDATE_IMAGES',

    SHOW_SAVED_MESSAGE: 'EDIT_VEHICLE/SHOW_SAVED_MESSAGE',

    FETCH_UNAVAILABLE_PERIODS: 'EDIT_VEHICLE/FETCH_UNAVAILABLE_PERIODS',
    SET_UNAVAILABLE_PERIODS: 'EDIT_VEHICLE/SET_UNAVAILABLE_PERIODS',

    FETCH_RENTED_PERIODS: 'EDIT_VEHICLE/FETCH_RENTED_PERIODS',
};

export const state = () => ({
    currentVehicle: null,
    vehicleImages: null,
    isLoadingImages: false,
    savedMessage: null,
    unavailablePeriods: [],
    rentedPeriods: [],
});

const isInfoEntered = (vehicle, subview) => {
    if (!vehicle) {
        return false;
    }

    return vehicle.infoStatuses[subview];
};

export const getters = {
    currentVehicle: state => state.currentVehicle,
    currentVehicleImages: state => state.vehicleImages,
    isLoadingImages: state => state.isLoadingImages,
    vehicleSavedMessage: state => state.savedMessage,
    vehicleUnavailablePeriods: state => state.unavailablePeriods,
    vehicleRentedPeriods: state => state.rentedPeriods,
    isVehicleCountrySet: state => {
        return state.currentVehicle && !!state.currentVehicle.country;
    },
    isVehicleMotorhome: state => {
        return state.currentVehicle && state.currentVehicle.subType === ASSET_SUB_TYPE_MOTORHOME;
    },
    isVehicleTypeOther: state => {
        return state.currentVehicle && state.currentVehicle.subType === ASSET_SUB_TYPE_OTHER_CAMPER;
    },
    isVehicleCampervan: state => {
        return state.currentVehicle && state.currentVehicle.subType === ASSET_SUB_TYPE_CAMPERVAN;
    },
    isVehicleCaravan: state => {
        return state.currentVehicle && state.currentVehicle.subType === ASSET_SUB_TYPE_CARAVAN;
    },
    isVehicleMotorized: state => {
        return (
            state.currentVehicle &&
            (state.currentVehicle.subType === ASSET_SUB_TYPE_MOTORHOME ||
                state.currentVehicle.subType === ASSET_SUB_TYPE_CAMPERVAN ||
                (state.currentVehicle.subType === ASSET_SUB_TYPE_OTHER_CAMPER && state.currentVehicle.isMotorized))
        );
    },
    isVehicleComplete: state => {
        return state.currentVehicle && state.currentVehicle.isCompleted;
    },
    currentVehicleIncompleteSubviews(state, getters) {
        if (!state.currentVehicle) {
            return [];
        }

        return Object.keys(getters.isCurrentVehicleSubviewComplete).filter(
            subview => !getters.isCurrentVehicleSubviewComplete[subview]
        );
    },
    isCurrentVehicleSubviewComplete: state => ({
        basic: isInfoEntered(state.currentVehicle, 'basic'),
        location: isInfoEntered(state.currentVehicle, 'location'),
        insurance: isInfoEntered(state.currentVehicle, 'insurance'),
        equipment: isInfoEntered(state.currentVehicle, 'equipment'),
        rules: isInfoEntered(state.currentVehicle, 'rules'),
        checkinCheckout: isInfoEntered(state.currentVehicle, 'checkinCheckout'),
        cancellation: isInfoEntered(state.currentVehicle, 'cancellation'),
        images: isInfoEntered(state.currentVehicle, 'images'),
        pricing: isInfoEntered(state.currentVehicle, 'pricing'),
        availability: isInfoEntered(state.currentVehicle, 'availability'),
        description: isInfoEntered(state.currentVehicle, 'description'),
        activation: isInfoEntered(state.currentVehicle, 'activation'),
    }),
    valueRequiresTrackingDevice: (_, getters) => {
        if (!getters.currentVehicle) {
            return false;
        }

        const trackingLimit = OMOCOM_INSURANCE_LIMITS.minValueForTrackingDeviceRequirement;

        return getters.currentVehicle.insuranceValue !== null && getters.currentVehicle.insuranceValue > trackingLimit;
    },
    qualifiesForPlatformInsurance: (_, getters) => {
        const vehicle = getters.currentVehicle;

        if (!vehicle) {
            return false;
        }

        const privatelyOwned = vehicle.isPrivatelyOwned === true;
        const value = vehicle.insuranceValue || null;
        const hasTrackingDevice = vehicle.hasApprovedTrackingDevice === true;
        const odometerValue = vehicle.currentOdometerValue || null;

        const baseRequirementsFulfilled =
            value !== null && (!getters.valueRequiresTrackingDevice || hasTrackingDevice) && privatelyOwned;

        // @note If these rules change they must also be updated in the backend
        switch (getters.currentVehicle.subType) {
            case ASSET_SUB_TYPE_CARAVAN:
                return baseRequirementsFulfilled;
            case ASSET_SUB_TYPE_MOTORHOME:
            case ASSET_SUB_TYPE_CAMPERVAN:
                return (
                    baseRequirementsFulfilled &&
                    odometerValue !== null &&
                    odometerValue < OMOCOM_INSURANCE_LIMITS.odometerValue
                );
            default:
                return false;
        }
    },
};

export const mutations = {
    [mutationTypes.VEHICLE_SET](state, payload) {
        state.currentVehicle = { ...payload };
    },
    [mutationTypes.VEHICLE_UNSET](state) {
        state.currentVehicle = null;
    },
    [mutationTypes.IMAGES_SET](state, payload) {
        state.vehicleImages = [...payload];
    },
    [mutationTypes.IMAGES_UNSET](state) {
        state.vehicleImages = null;
    },
    [mutationTypes.LOADING_IMAGES_SET](state, payload) {
        state.isLoadingImages = payload;
    },
    [mutationTypes.SAVED_MESSAGE_SET](state, payload) {
        state.savedMessage = payload;
    },
    [mutationTypes.SAVED_MESSAGE_CLEAR](state) {
        state.savedMessage = null;
    },
    [mutationTypes.UNAVAILABLE_PERIODS_SET](state, payload) {
        state.unavailablePeriods = payload;
    },
    [mutationTypes.UNAVAILABLE_PERIODS_CLEAR](state) {
        state.unavailablePeriods = [];
    },
    [mutationTypes.RENTED_PERIODS_SET](state, payload) {
        state.rentedPeriods = payload;
    },
    [mutationTypes.RENTED_PERIODS_CLEAR](state) {
        state.rentedPeriods = [];
    },
};

export const actions = {
    [actionTypes.FETCH_VEHICLE]({ commit }, id) {
        return new VehicleService(this).getVehicle(id).then(vehicle => {
            commit(mutationTypes.VEHICLE_SET, vehicle);
        });
    },

    [actionTypes.RELOAD_CURRENT_VEHICLE]({ commit, state, dispatch }, data) {
        if (!state.currentVehicle && !data) {
            return Promise.resolve();
        }

        const vehicleId = data?.id || state.currentVehicle?.id;

        if (data?.id) {
            return dispatch(myVehiclesActionTypes.FETCH_VEHICLES);
        }

        return new VehicleService(this).getVehicle(vehicleId).then(vehicle => {
            commit(mutationTypes.VEHICLE_SET, vehicle);
        });
    },

    [actionTypes.SET_CURRENT_VEHICLE]({ commit }, vehicle) {
        commit(mutationTypes.VEHICLE_SET, vehicle);
    },

    /**
     * Note: This action reloads the user's vehicles list (myVehicles) when
     * currentVehicle is the same as myFirstVehicle
     */
    [actionTypes.SAVE_CURRENT_VEHICLE]({ commit, state, dispatch, getters }, data) {
        const vehicleId = data?.id || state.currentVehicle?.id;

        return new VehicleService(this).updateVehicle(vehicleId, data).then(updatedVehicle => {
            if (getters.myFirstVehicle && state.currentVehicle?.id === getters.myFirstVehicle.id) {
                return dispatch(myVehiclesActionTypes.FETCH_VEHICLES).then(() => {
                    commit(mutationTypes.VEHICLE_SET, updatedVehicle);

                    return updatedVehicle;
                });
            }

            commit(mutationTypes.VEHICLE_SET, updatedVehicle);

            return updatedVehicle;
        });
    },

    [actionTypes.CONFIRM_INSURANCE_REQUIREMENTS]({ commit, state }, specificId) {
        let id = specificId;

        if (!specificId) {
            id = state.currentVehicle.id;
        }

        return new VehicleService(this).confirmInsuranceRequirements(id).then(updatedVehicle => {
            commit(mutationTypes.VEHICLE_SET, updatedVehicle);
        });
    },

    [actionTypes.FETCH_IMAGES]({ commit, state }, data) {
        commit(mutationTypes.LOADING_IMAGES_SET, true);

        const vehicleId = data?.id || state.currentVehicle?.id;

        return new VehicleService(this).getImages(vehicleId).then(images => {
            commit(mutationTypes.IMAGES_SET, images);
            commit(mutationTypes.LOADING_IMAGES_SET, false);
        });
    },

    [actionTypes.UPDATE_IMAGES]({ commit }, images) {
        commit(mutationTypes.IMAGES_SET, images);
    },

    [actionTypes.SHOW_SAVED_MESSAGE]({ commit }, message) {
        commit(mutationTypes.SAVED_MESSAGE_SET, message);

        setTimeout(() => {
            commit(mutationTypes.SAVED_MESSAGE_CLEAR);
        }, SAVE_MESSAGE_TIMEOUT);
    },

    [actionTypes.FETCH_UNAVAILABLE_PERIODS]({ commit, state }, data) {
        const vehicleId = data?.id || state.currentVehicle?.id;

        return new VehicleService(this).getUnavailablePeriods(vehicleId).then(periods => {
            commit(mutationTypes.UNAVAILABLE_PERIODS_SET, periods);
        });
    },

    [actionTypes.SET_UNAVAILABLE_PERIODS]({ commit }, periods) {
        commit(mutationTypes.UNAVAILABLE_PERIODS_SET, periods);
    },

    [actionTypes.FETCH_RENTED_PERIODS]({ commit, state }, data) {
        const vehicleId = data?.id || state.currentVehicle?.id;

        return new VehicleService(this).getRentedPeriods(vehicleId).then(periods => {
            commit(mutationTypes.RENTED_PERIODS_SET, periods);
        });
    },

    [actionTypes.SET_REGISTRATION_DOCUMENT]({ commit, state }, data) {
        const vehicleId = data?.id || state.currentVehicle?.id;
        const reqData = { key: 'registrationDocumentImage', value: data.file };

        return new VehicleService(this).setRegistrationDocument(vehicleId, reqData).then(response => {
            commit(mutationTypes.VEHICLE_SET, response);
        });
    },

    [actionTypes.DELETE_REGISTRATION_DOCUMENT]({ commit, state }, id) {
        const vehicleId = id || state.currentVehicle?.id;

        return new VehicleService(this).deleteRegistrationDocument(vehicleId).then(response => {
            commit(mutationTypes.VEHICLE_SET, response);
        });
    },

    [actionTypes.CLEAR]({ commit }) {
        commit(mutationTypes.VEHICLE_UNSET);
        commit(mutationTypes.IMAGES_UNSET);
        commit(mutationTypes.SAVED_MESSAGE_CLEAR);
        commit(mutationTypes.UNAVAILABLE_PERIODS_CLEAR);
        commit(mutationTypes.RENTED_PERIODS_CLEAR);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
    mutationTypes,
};
